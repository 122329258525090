
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as style from './HeaderParagraph.module.scss';

export const fragment = graphql`
  fragment HeaderParagraphFragment on WpPage_Flexlayouts_FlexibleLayouts_HeaderParagraph {  
     sectionHeader
     paragraph
     
  }
`

const HeaderParagraph = ({sectionHeader,paragraph}) => {

  return (
        <>
          <Container fluid={true}>
            <Row>
              <Col sm={12}>
                {sectionHeader && <h1 className={`mb-3 mt-5 mt-lg-2 text-center text-uppercase  ${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />}
                <p className={` mb-3 text-center font-weight-normal ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }} />
              </Col>
            </Row>
          </Container>
        </>
  )
}

export default HeaderParagraph

